<template>
  <div></div>
</template>

<script>
import { toRegister } from "@/utils/common.js";

export default {
  name: 'elderCanteen',
  async mounted() {
    if (await toRegister(this.$route.path, this.$route.query, '热门活动报名')) {
      this.$router.replace({
        name: 'eldCanPlaceOrder'
      });
    }
  }
};
</script>

<style></style>
